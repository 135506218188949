<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <SiteToolbar
        v-if="Object.keys(site).length !== 0"
        :site="site"
        closing
      />
    </b-card>

    <b-card class="card-statistics">
      <div class="card-statistics-tools">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="col-picker">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{mode: 'range', dateFormat: 'Y-m-d'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-1"
              :to="{ name: 'dashboard-site' }"
            >
              V1
            </b-button>
          </div>

        </div>
      </div>
      <div>
        <b-row
          v-if="statsV2Data.length !== 0"
          class="row-statistics"
        >
          <b-col
            md="12"
            cols="12"
          >
            <statistic-tabs
              :card-title="$t('Statistics')"
              :stats-data="statsV2Data"
              :account-currency="account.currency"
              @itemField="setField"
            />
          </b-col>
          <b-col
            md="12"
            cols="12 mt-3"
          >
            <SiteStatsTable
              :selected-range="selectedRangeFormated"
              :account-currency="account.currency"
              :stats-data="statsV2Data"
              :stats-revenue="statsRevenue"
              :stats-revenue-range="statsRevenueMonthly"
            />
          </b-col>
        </b-row>
        <div
          v-else-if="graphicShow && statsV2Data.length === 0"
          class="card-nodata"
        >
          <p class="card-text">
            {{ $t('sites.nodata_period') }}
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import getFormattedDate, { getFormattedDateMonth } from '@/utils/date-formatter'
import currencyFormatter from '@/utils/currency-formatter'
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import SiteToolbar from '@/views/dashboard/sites/SiteToolbar.vue'
import flatPickr from 'vue-flatpickr-component'
import StatisticTabs from './stats/StatisticTabsLiteV2.vue'
import SiteStatsTable from './stats/SiteStatsTableV2.vue'

export default {
  components: {
    BRow,
    BCol,
    Breadcrumbs,
    UserToolbar,
    BCard,
    StatisticTabs,
    BButton,
    SiteToolbar,
    flatPickr,
    SiteStatsTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      account: {},
      site: {},
      blockSubtitle: '',
      authUserData: getUserData(),
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateMonth,
      statsV2Data: [],
      statsRevenue: [],
      statsRevenueMonthly: {},
      statsBlocks: {
        show: {},
        click: {},
      },
      currentField: 'show',
      graphicShow: false,
      rangePicker: [getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), getFormattedDate(new Date())],
    }
  },
  computed: {
    filterStatsBlocks() {
      return this.statsBlocks[this.currentField] || {}
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
  },
  watch: {
    currentField(newValue) {
      if (newValue === 'amount') {
        this.graphicShow = false
      }
    },
  },
  created() {
    this.getUserData()
    this.getSiteData()
    this.getStatsV2Data()
    this.blockSubtitle = `${this.selectedRangeFormated.from.replaceAll('-', '.')}-${this.selectedRangeFormated.to.replaceAll('-', '.')}`
    this.getStatsRevenue(this.selectedRangeFormated.from)
    this.getStatsRevenueMonthly(this.selectedRangeFormated.from)
  },
  methods: {
    scrollToTop(selector) {
      const rootEle = document.documentElement
      const element = document.querySelector(selector)
      const elementOffsetTop = element.offsetTop

      setTimeout(() => {
        rootEle.scrollTo({
          top: elementOffsetTop - 200,
          behavior: 'smooth',
        })
      }, 500)
    },
    toggleRange(e) {
      this.rangePicker = e
      this.blockSubtitle = `${this.selectedRangeFormated.from.replaceAll('-', '.')}-${this.selectedRangeFormated.to.replaceAll('-', '.')}`
      this.getStatsV2Data()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.setBreabcrumbs()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || {}
      this.setBreabcrumbs()
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.menu.sites'),
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          active: true,
        },
      ]
    },
    async getStatsV2Data() {
      this.loading = true
      this.statsV2Data = []
      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        with_sources: false,
      }
      const reqParams = Object.assign(params, this.selectedRangeFormated)
      const responseData = await useJwt.getSiteStatsV2ByType(reqParams)
      this.statsV2Data = responseData.data.items || []
      this.loading = false
    },
    async getStatsRevenue(from) {
      this.statsRevenue = []
      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from,
      }
      const reqParams = Object.assign(params, this.selectedRangeFormated)
      const responseData = await useJwt.getSiteStatsRevenue(reqParams)
      this.statsRevenue = responseData.data.items || []
    },
    async getStatsRevenueMonthly(from) {
      this.statsRevenueMonthly = {}
      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from,
      }

      const responseData = await useJwt.getSiteStatsRevenueTotal(params)
      this.statsRevenueMonthly = responseData.data || {}
    },
    setField(field) {
      this.currentField = field
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped lang="scss">
.card-statistics-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 440px) {
  [dir] #app {
    .card-statistics-tools {
      flex-wrap: wrap;
    }
  }
}
</style>
