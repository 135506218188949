<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span>Numbers are not final! It may take up to 24h for daily numbers to update!</span>
      </div>
    </b-alert>

    <b-table
      small
      :items="statsData"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      striped
      foot-clone
    >
      <template #head(date)>
        {{ $t('stats.date') }}
      </template>
      <template #head(impressions)>
        {{ $t('stats.shows') }}
      </template>
      <template #head(revenue)>
        {{ $t('stats.amount') }}
      </template>
      <template #cell(date)="row">
        <span v-if="row">
          <a
            href="#"
            :class="row.item._showDetails === true ? 'text-secondary' : ''"
            @click.prevent="showDetails(row)"
          >
            <strong>{{ getFormattedDate(new Date(row.item.date)) }}</strong>
            <feather-icon
              class="ml-15"
              :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              size="20"
            />
          </a>
        </span>
        <span v-else>
          {{ row.item.date }}
        </span>
      </template>
      <template #row-details="{ item }">
        <div v-if="item">
          <div
            v-if="loading_hourly && !statsDay[item.date]"
            class="text-center"
          >
            <b-spinner label="Loading..." />
          </div>

          <b-table
            v-else-if="!loading_hourly && statsDay[item.date].length"
            small
            :items="statsDay[item.date]"
            :fields="fields"
            responsive
            striped
            thead-class="d-none"
            table-variant="primary"
          >
            <template #cell(date)="row">
              {{ getFormattedDateTimeZone(row.item.date) }}
            </template>
            <template #cell(impressions)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>
            <template #cell(clicks)="data">
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
            </template>
            <template #cell(revenue)="data">
              {{ data.value ? currency(accountCurrency, data.value) : 0 }}
            </template>
          </b-table>
        </div>
      </template>

      <template #cell(revenue)="data">
        <a
          href="#"
          @click.prevent="toggleClick('revenue', data.item.date)"
        >
          {{ data.value ? currency(accountCurrency, data.value) : 0 }}
          <feather-icon
            class="ml-15"
            :icon="toggleData['revenue'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
            size="20"
          />
        </a>
        <div v-if="toggleData['revenue'].includes(data.item.date)">
          <div
            v-for="(value, name) in showStatsRevenue('revenue', data.item.date)"
            :key="value"
          >
            <small
              class="text-primary cursor-pointer"
              @click="name !== 'banner' && name !== 'cpcfeed'
                ? showSiteRevenueDetails(name, data.item.date, 'revenue')
                : name !== 'cpcfeed'
                  ? showCampaignRevenueDetails(name, data.item.date, 'revenue')
                  : showCPCFeedDetails(name, data.item.date, 'revenue')"
            >
              {{ $t(`stats.adstypes.${name}`) }}: {{ currency(accountCurrency, value) }}
            </small>
          </div>
        </div>
      </template>

      <template #cell(impressions)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(clicks)="data">
        <a
          href="#"
          @click.prevent="toggleClick('clicks', data.item.date)"
        >

          {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          <feather-icon
            class="ml-15"
            :icon="toggleData['clicks'].includes(data.item.date) === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
            size="20"
          />
        </a>
        <div v-if="toggleData['clicks'].includes(data.item.date)">
          <div
            v-for="(value, name, idx) in showStatsRevenue('clicks', data.item.date)"
            :key="`clicks${idx}`"
          >
            <small
              class="text-primary cursor-pointer"
              @click="name !== 'banner' && name !== 'cpcfeed'
                ? showSiteRevenueDetails(name, data.item.date, 'clicks')
                : name !== 'cpcfeed'
                  ? showCampaignRevenueDetails(name, data.item.date, 'clicks')
                  : showCPCFeedDetails(name, data.item.date, 'clicks')"
            >
              {{ $t(`stats.adstypes.${name}`) }}: {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value) }}
            </small>
          </div>
        </div>
      </template>

      <template #foot(date)="data">
        <strong :data-label="data.label">{{ $t('Records') }}: {{ statsData.length }}</strong>
      </template>

      <template #foot(clicks)="data">
        <span
          style="font-weight: bold; cursor: pointer;"
          @click.prevent="toggleTotals('clicks')"
        >
          <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('clicks')) }}</strong>
          <feather-icon
            class="ml-15"
            :icon="toggleTotalsData['clicks'] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
            size="20"
          />
        </span>
        <div v-if="toggleTotalsData['clicks']">
          <div
            v-for="(value, name) in statsRevenueRange.clicks"
            :key="value"
          >
            <small
              class="text-primary cursor-pointer"
              @click="name !== 'banner' && name !== 'cpcfeed'
                ? showSiteRevenueTotalDetails(name, selectedRange.from, 'clicks')
                : name !== 'cpcfeed'
                  ? showCampaignRevenueTotalDetails(name, selectedRange.from, 'clicks')
                  : showCPCFeedTotalDetails(name, selectedRange.from, 'clicks')"
            >
              <strong style="text-transform: initial;">{{ $t(`stats.adstypes.${name}`) }}: {{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(value) }}</strong>
            </small>
          </div>
        </div>
      </template>

      <template #foot(impressions)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('impressions')) }}</strong>
      </template>

      <template #foot(revenue)="data">
        <span
          style="font-weight: bold; cursor: pointer;"
          @click.prevent="toggleTotals('revenue')"
        >
          <strong :data-label="data.label">{{ currency(accountCurrency, totalFormat('revenue')) }}</strong>
          <feather-icon
            class="ml-15"
            :icon="toggleTotalsData['revenue'] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
            size="20"
          />
        </span>
        <div v-if="toggleTotalsData['revenue']">
          <div
            v-for="(value, name) in statsRevenueRange.revenue"
            :key="value"
          >
            <small
              class="text-primary cursor-pointer"
              @click="name !== 'banner' && name !== 'cpcfeed'
                ? showSiteRevenueTotalDetails(name, selectedRange.from, 'revenue')
                : name !== 'cpcfeed'
                  ? showCampaignRevenueTotalDetails(name, selectedRange.from, 'revenue')
                  : showCPCFeedTotalDetails(name, selectedRange.from, 'revenue')"
            >
              <strong style="text-transform: initial;">{{ $t(`stats.adstypes.${name}`) }}: {{ currency(accountCurrency, value) }}</strong></small>
          </div>
        </div>
      </template>
    </b-table>
    <b-modal
      id="modal-stats"
      ref="modal-stats"
      centered
      size="lg"
      :title="modalTitle"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <SiteStatsModal
        :account-currency="accountCurrency"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
        :sidebar-data-type="sidebarDataType"
      />
    </b-modal>
    <b-modal
      id="modal-stats-totals"
      ref="modal-stats-totals"
      centered
      size="lg"
      :title="modalTitleTotals"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <SiteStatsModalTotals
        :account-currency="accountCurrency"
        :sidebar-data="sidebarDataTotals"
        :sidebar-data-field="sidebarDataField"
        :sidebar-data-type="sidebarDataType"
        :selected-range="selectedRange"
      />
    </b-modal>
    <!-- <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <SiteStatsSidebar
        ref="sidebar"
        :account-currency="accountCurrency"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
        :sidebar-data-type="sidebarDataType"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-totals-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <SiteStatsSidebarTotals
        ref="sidebar-totals"
        :account-currency="accountCurrency"
        :sidebar-data="sidebarDataTotals"
        :sidebar-data-field="sidebarDataField"
        :sidebar-data-type="sidebarDataType"
        :selected-range="selectedRange"
      />
    </b-sidebar> -->
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime, getFormattedDateTimeZone } from '@/utils/date-formatter'
import {
  BTable,
  // BButton,
  BAlert,
  BSpinner,
  // BSidebar,
  BModal,
  VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import SiteStatsSidebar from './SiteStatsSidebar.vue'
import SiteStatsModal from './SiteStatsModal.vue'
import SiteStatsModalTotals from './SiteStatsModalTotals.vue'
// import SiteStatsSidebarTotals from './SiteStatsSidebarTotals.vue'

export default {
  components: {
    // BButton,
    BTable,
    BAlert,
    BSpinner,
    // BSidebar,
    BModal,
    // SiteStatsSidebar,
    // SiteStatsSidebarTotals,
    SiteStatsModal,
    SiteStatsModalTotals,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    statsData: {
      type: Array,
      required: true,
    },
    statsRevenue: {
      type: Array,
      required: true,
    },
    statsRevenueRange: {
      type: Object,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
    selectedRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      getFormattedDateTimeZone,
      fields: [
        { key: 'date', label: 'Date', tdClass: 'td-width' },
        { key: 'impressions', label: 'Impressions', tdClass: 'td-width' },
        { key: 'clicks', label: 'Clicks', tdClass: 'td-width' },
        { key: 'revenue', label: 'Income', tdClass: 'td-width' },
      ],
      sortBy: 'date',
      sortDesc: true,
      sortDirection: 'asc',
      statsDay: {},
      loading: true,
      loading_hourly: true,
      toggleData: {
        clicks: [],
        revenue: [],
      },
      toggleTotalsData: {
        clicks: false,
        revenue: false,
      },
      sidebarData: [],
      sidebarDataTotals: {},
      revenueData: [],
      IsOpenSidebar: false,
      sidebarDataField: '',
      sidebarDataType: '',
    }
  },
  computed: {
    modalTitle() {
      let title = ''

      if (this.sidebarData[0] !== undefined && this.sidebarDataType) {
        title = `${this.$i18n.t(`stats.adstypes.${this.sidebarDataType}`)} (${getFormattedDate(new Date(this.sidebarData[0].date))})`
      }

      return title
    },
    modalTitleTotals() {
      let title = ''

      if (Object.keys(this.sidebarDataTotals).length > 0 && this.sidebarDataType) {
        title = `${this.$i18n.t(`stats.adstypes.${this.sidebarDataType}`)} (${this.$i18n.t('date_range', { from: getFormattedDate(new Date(this.selectedRange.from)), to: getFormattedDate(new Date(this.selectedRange.to)) })})`
      }

      return title
    },
  },
  methods: {
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    totalFormat(field) {
      let total = 0
      this.statsData.forEach(i => {
        if (typeof i[field] === 'object') {
          total += +this.countObjectTotals(i[field])
        } else {
          total += i[field]
        }
      })
      return total
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.date]) {
          this.getStatsDay(row.item.date)
        }
      }
    },
    async showCampaignRevenueDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
      }

      await useJwt.getStatsBannersSites(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            if (currentItem.length > 0) {
              this.sidebarData = currentItem
              const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
              if (ids.length > 0) {
                await store.dispatch('dashboard-campaings-stats/fetchCampaignNamesById', ids)
              }
              // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
              this.$refs['modal-stats'].show()
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCampaignRevenueTotalDetails(type, date, field) {
      this.sidebarDataTotals = {}
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
      }

      await useJwt.getStatsBannersSites(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-campaings-stats/fetchCampaignNamesById', ids)
            }
            // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCPCFeedDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: 'cpcfeed',
      }

      await useJwt.getCampaignStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            if (currentItem.length > 0) {
              this.sidebarData = currentItem
              const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
              if (ids.length > 0) {
                await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
              }
              // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
              this.$refs['modal-stats'].show()
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showCPCFeedTotalDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: 'cpcfeed',
      }

      await useJwt.getCampaignStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
            }
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showSiteRevenueTotalDetails(type, date, field) {
      this.sidebarDataTotals = {}
      this.sidebarDataType = type
      this.sidebarDataField = field

      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: type,
      }

      await useJwt.getSiteStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const newItems = this.getRangeTotalsByType(response.data.items, field)
            this.sidebarDataTotals = newItems
            const ids = Object.keys(newItems).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-partners-stats/fetchPartnerNamesById', ids)
            }
            // this.$root.$emit('bv::toggle::collapse', 'sidebar-totals-right')
            this.$refs['modal-stats-totals'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    async showSiteRevenueDetails(type, date, field) {
      this.sidebarData = []
      this.sidebarDataField = field
      this.sidebarDataType = type
      const params = {
        site_id: this.$route.params.siteId,
        type: 'daily',
        from: getFormattedDate(new Date(date)),
        ad_type: type,
      }

      await useJwt.getSiteStatsEcpm(params)
        .then(async response => {
          if (response.data && response.data.items && response.data.items.length > 0) {
            const currentItem = response.data.items.filter(i => i.date === date)
            this.sidebarData = currentItem
            const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
            if (ids.length > 0) {
              await store.dispatch('dashboard-partners-stats/fetchPartnerNamesById', ids)
            }

            // this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
            this.$refs['modal-stats'].show()
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors.length) {
              error.response.data.errors.forEach(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.detail,
                  },
                })
              })
            }
          }
        })
    },
    toggleClick(field, value) {
      const idx = this.toggleData[field].indexOf(value)

      if (idx !== -1) {
        this.toggleData[field].splice(idx, 1)
      } else {
        this.toggleData[field].push(value)
      }
    },
    toggleTotals(field) {
      this.toggleTotalsData[field] = !this.toggleTotalsData[field]
    },
    async getStatsDay(day) {
      this.loading_hourly = true
      this.statsDay[day] = null
      const params = {
        site_id: this.$route.params.siteId,
        type: 'hourly',
        date: getFormattedDate(new Date(day)),
        with_sources: false,
      }

      const responseData = await useJwt.getSiteStatsV2ByType(params)
      this.statsDay[day] = responseData.data.items || null
      this.loading_hourly = false
    },
    showStatsRevenue(field, date) {
      const currentDateData = this.statsRevenue.find(sr => sr.date === date)
      if (Object.keys(currentDateData).length > 0) {
        return currentDateData[field]
      }
      return ''
    },
    getRangeTotalsByType(data, field) {
      return data.reduce((acc, obj) => {
        Object.entries(obj[field]).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value
        })
        return acc
      }, {})
    },
  },
}
</script>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 11%;
}
</style>
<style scoped>
  [dir] .b-table-has-details .btn-flat-primary {
    padding: 0!important;
  }
</style>
